import React, { useState, FunctionComponent } from 'react';

import Button, { VARIANTS } from 'common/components/Button';
import Dropdown from 'common/components/Dropdown';
import Input from 'common/components/Forms/Input';
import Checkbox from 'common/components/Forms/Checkbox';
import type { Role } from '@socrata/core-roles-api';

import { ConnectionConfig } from 'authentication/types';

import RegExTester from './RegExTester';
import './InputForConnections.scss';

interface InputsForConnectionProps {
  /** Connection config that this is editing */
  connectionConfig: ConnectionConfig;

  // functions to update state etc.
  index: number;
  onUpdateValue: (index: number, type: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdateRoleId: (index: number, roleId: number) => void;
  onRemoveConnection: (index: number) => void;
  onUpdateIgnoreEmailRegexOnLoginPage: (index: number, value: boolean) => void;
  dropdownOptions: Role[];
}

const InputsForConnection: FunctionComponent<InputsForConnectionProps> = ({
  connectionConfig,
  index,
  onUpdateValue,
  onUpdateRoleId,
  onRemoveConnection,
  onUpdateIgnoreEmailRegexOnLoginPage,
  dropdownOptions
}) => {
  const [regexTest, setRegexText] = useState('');

  const { match, connection, roleId, alias, ignoreEmailRegexOnLoginPage } = connectionConfig;

  return (
    <div className="input-for-connections">
      <Input
        label="Auth0 Connection Name"
        name="connection"
        value={connection}
        onChange={onUpdateValue(index, 'connection')}
        required
      />
      <Input label="Alias" name="alias" value={alias} onChange={onUpdateValue(index, 'alias')} />
      <div style={{ display: 'flex' }}>
        <Input label="Email Regex" name="match" value={match} onChange={onUpdateValue(index, 'match')} />
        <RegExTester
          regexString={match}
          testString={regexTest}
          index={index}
          onUpdateValue={(e: React.ChangeEvent<HTMLInputElement>) => setRegexText(e.target.value)}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Default Role (hit "Space" to open if clicking doesn't work)</label>
        <Dropdown
          options={dropdownOptions}
          value={roleId}
          onSelection={({ value }: { value: number }) => onUpdateRoleId(index, value)}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          name="ignoreEmailRegexOnLoginPage"
          checked={Boolean(ignoreEmailRegexOnLoginPage)}
          label="Ignore Email Regex On Login Page"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onUpdateIgnoreEmailRegexOnLoginPage(index, e.target.checked)
          }
        />
      </div>
      <Button
        variant={VARIANTS.ERROR}
        style={{ marginTop: '15px' }}
        onClick={() => onRemoveConnection(index)}
      >
        Remove Connection
      </Button>
    </div>
  );
};

export default InputsForConnection;
