import React from 'react';
import ReactDOM from 'react-dom';

import Auth0ForcedConnections from './auth0/auth0_forced_connections';

/**
 * Call this to render a React component underneath the label for a config property.
 * @param {string} propertyName Name of property to render editor for. Editor will be rendered underneath the label.
 * @param {class} componentToRender Component to render for given property
 */
const addComponent = (propertyName, componentToRender) => {
  const labelDomNode = document.querySelector(`.property > label[title="${propertyName}"]`);
  if (labelDomNode) {
    const targetNode = document.createElement('div');
    labelDomNode.appendChild(targetNode);
    ReactDOM.render(React.createElement(componentToRender), targetNode);
  }
};

// auth0 / auth0_forced_connections
addComponent('auth0_forced_connections', Auth0ForcedConnections);
