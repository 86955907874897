import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';

import Input from 'common/components/Forms/Input';

const REGEX_OUTCOMES = {
  INVALID: 'INVALID',
  PASS: 'PASS',
  FAIL: 'FAIL'
};

/**
 * Returns true/false for 'testString' matching the regex in 'regexString'
 * Returns null if 'regexString' is not a valid regex
 */
const isMatch = (regexString: string, testString: string) => {
  try {
    return RegExp(regexString).test(testString) ? REGEX_OUTCOMES.PASS : REGEX_OUTCOMES.FAIL;
  } catch (e) {
    console.warn(e);
    return REGEX_OUTCOMES.INVALID;
  }
};

const renderMatchText = (regexString: string, testString: string) => {
  if (testString === '' || regexString === '') {
    return null;
  }

  switch (isMatch(regexString, testString)) {
    case REGEX_OUTCOMES.PASS:
      return "It's a match! ✅";
    case REGEX_OUTCOMES.FAIL:
      return 'No match ❌';
    default:
    case REGEX_OUTCOMES.INVALID:
      return 'Invalid Regex! 🚫';
  }
};

interface RegExTesterProps {
  /** Current text to test against regex */
  testString: string;

  /** Current regex to match on */
  regexString: string;

  /** Index in connections array */
  index: number;

  /** Function to call when input is typed into */
  onUpdateValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RegExTester: FunctionComponent<RegExTesterProps> = ({
  testString,
  regexString,
  index,
  onUpdateValue
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Input label="Test Regex Here!" name="regex_text" value={testString} onChange={onUpdateValue} />
    <div style={{ marginLeft: '15px' }}>{renderMatchText(regexString, testString)}</div>
  </div>
);

export default RegExTester;
