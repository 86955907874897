import React, { useEffect, useState, FunctionComponent } from 'react';
import type { Role } from '@socrata/core-roles-api';

import Spinner from 'common/components/Spinner';

import Editor from './Editor';

/**
 * This root component is just for fetching the list of roles.
 * After fetching it will render the Editor component.
 */
const Auth0ForcedConnections: FunctionComponent = () => {
  const [roles, setRoles] = useState<Role[] | undefined>(undefined);

  const fetchRoles = async (): Promise<void> => {
    // fetch the list of roles (we're a superadmin if we're here so we can definitely see them)
    const rolesResponse = await fetch('/api/roles');
    const result = await rolesResponse.json();
    setRoles(result);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return roles ? <Editor roles={roles as any} /> : <Spinner />;
};

export default Auth0ForcedConnections;
