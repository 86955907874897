import React from 'react';
import { FunctionComponent } from 'react';

import { ConnectionConfig } from 'authentication/types';

import InputsForConnection from './InputsForConnection';

interface ConnectionListProps {
  rolesDropdownOptions: any[];
  forcedConnections: ConnectionConfig[];
  onUpdateValue: (index: number, type: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdateRoleId: (index: number, roleId: number) => void;
  onUpdateIgnoreEmailRegexOnLoginPage: (index: number, value: boolean) => void;
  onRemoveConnection: (index: number) => void;
}

/** List of connections with Inputs */
const ConnectionList: FunctionComponent<ConnectionListProps> = ({
  rolesDropdownOptions,
  forcedConnections,
  onUpdateValue,
  onUpdateRoleId,
  onUpdateIgnoreEmailRegexOnLoginPage,
  onRemoveConnection
}) => (
  <div>
    {forcedConnections.map((forcedConnection, index) => (
      <InputsForConnection
        key={index}
        connectionConfig={forcedConnection}
        index={index}
        dropdownOptions={rolesDropdownOptions}
        onUpdateValue={onUpdateValue}
        onUpdateRoleId={onUpdateRoleId}
        onUpdateIgnoreEmailRegexOnLoginPage={onUpdateIgnoreEmailRegexOnLoginPage}
        onRemoveConnection={onRemoveConnection}
      />
    ))}
  </div>
);

export default ConnectionList;
